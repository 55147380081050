import { Workbook } from 'exceljs';
import { isEmpty } from 'lodash-es';
import { FILE_TYPES } from '../app.constant';

export function downloadFile(res, fileName, fileType) {
    const blob = new Blob([res], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
}

/**
 * Function to format the file with user name
 * @param fileName 
 * @param reviewDetails 
 */
export function formatFileName(fileName, reviewDetails) {
    if (!fileName || isEmpty(reviewDetails)) {
        return fileName;
    }
    let formatedFileName = fileName;
    const fileNmaeArr = fileName.split('-');
    if (fileNmaeArr?.length > 1) {
        formatedFileName = fileNmaeArr[1];
    }
    if (formatedFileName?.split('.')?.length > 1) {
        const fileExt = formatedFileName?.split('.');
        formatedFileName = `${fileExt[0]}_${reviewDetails?.firstname[0]}${reviewDetails.lastname}.${fileExt[fileExt.length - 1]}`;
    } else {
        formatedFileName = `${formatedFileName}_${reviewDetails?.firstname[0]}${reviewDetails.lastname}`;
    }
    return formatedFileName;
}

export function removeFilePath(fileName) {
    const fileArrr = fileName.split('/');
    return fileArrr[fileArrr.length - 1];
}

export function exportExcelFile(excelData, sheetName, fileName) {
    const workbook = new Workbook();
    var arr = []
    for (var i = sheetName.length - 1; i >= 0; i--) {
        arr.push(sheetName[i])
    }
    sheetName = arr
    excelData.slice().reverse().forEach((excelSheet, index) => {
        const worksheet = workbook.addWorksheet(sheetName[index]);
        //Add Row and formatting
        worksheet.mergeCells('A1', 'F1');
        const titleRow = worksheet.getCell('A1');
        titleRow.value = excelSheet[0];
        titleRow.font = {
            name: 'Calibri',
            size: 16,
        }
        titleRow.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
        //Adding Header Row
        const headerRow = worksheet.addRow(excelSheet[1]);
        formatHeaderCell(headerRow);
        // Adding Data with Conditional Formatting
        for (let excelContents = 2; excelContents < excelSheet.length; excelContents++) {
            const row = worksheet.addRow(excelSheet[excelContents]);
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        }
    });
    workbook.xlsx.writeBuffer().then((data) => {
        downloadFile(data, `${fileName}.xlsx`, FILE_TYPES.XLSX)
    })
}

/**
 * Function to download the given data as excel file
 * @param data 
 * @param callback 
 */
export function exportUserLists(data, callback) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Users');
    const headerRow = worksheet.addRow(Object.keys(data[0]));
    formatHeaderCell(headerRow);
    for (const x1 of data) {
        worksheet.addRow(Object.values(x1));
    }
    workbook.xlsx.writeBuffer().then((bufferedData) => {
        downloadFile(bufferedData, 'Users' + '.xlsx', FILE_TYPES.XLSX);
        if (typeof callback === 'function') {
            callback();
        }
    }, () => {
        callback();
    })
}

export function exportCommentExcelFile(excelData, sheetName, fileName) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(sheetName);
    //Add Row and formatting
    worksheet.mergeCells('A1', 'I1');
    const titleRow = worksheet.getCell('A1');
    titleRow.value = excelData[0];
    titleRow.font = {
        name: 'Calibri',
        size: 16,
    }
    //Adding Header Row
    worksheet.addRow(excelData[1]);
    // Adding Data with Conditional Formatting
    excelData[2].forEach(d => {
        worksheet.addRow(d);
    });
    workbook.xlsx.writeBuffer().then((data) => {
        downloadFile(data, `${fileName}.xlsx`, FILE_TYPES.XLSX)
    })
}

function formatHeaderCell(headerRow) {
    headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '2E2E2E' },
            bgColor: { argb: '2E2E2E' }
        }
        cell.font = {
            color: { argb: 'FFFFFF' },
            size: 12
        }
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    })
}