import { Component, OnInit, ElementRef, AfterViewInit, ChangeDetectorRef, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StaticContentService } from '../../../app/service/features/static-content.service';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash-es';
import { ROUTES } from '../../app-routes.constant';
import { TdrsHttpEnvironmentService } from '../../service/environment';
import { isValidURL } from '../../service/library/library';
import { downloadFile } from '../../common/file-download';
import { FILE_TYPES, WARNINGS,UploadStatus } from '../../app.constant';
import { UserDetailService } from '../../../app/service/common/user/user-detail.service';

@Component({
  selector: 'dd-review-guidelines',
  templateUrl: './review-guidelines.component.html',
  styleUrls: ['./review-guidelines.component.scss']
})
export class ReviewGuidelinesComponent implements OnInit, AfterViewInit {
  public legalGuideContent;
  public disclaimerContent;
  public photovideoguideContent;
  public legalData;
  public legalDataWarning;
  public disclaimerData;
  public disclaimerDataWarning;
  public photovideoguideData;
  public photovideoguideDataWarning;
  public selectedTab;
  public showSpinner: boolean = false;
  public showDisclaimer: boolean = false;
  public disclaimerFile: File = null;
  public disclaimerDate: Date = null;
  public isValidDisclaimerFile: boolean = true;
  public isValidDisclaimerDate: boolean = true;

  public isSuperAdmin = false;

  public router: Router;
  public sanitizer: DomSanitizer;
  public tdrsHttpEnvironmentService: TdrsHttpEnvironmentService;
  public Activatedroute: ActivatedRoute;
  public cdr: ChangeDetectorRef;
  public staticContentService: StaticContentService;
  public elementRef: ElementRef;
  public vps: ViewportScroller;
  constructor(public injector: Injector,public userDetailService: UserDetailService) {
    this.router = injector.get(Router);
    this.sanitizer = injector.get(DomSanitizer);
    this.tdrsHttpEnvironmentService = injector.get(TdrsHttpEnvironmentService);
    this.Activatedroute = injector.get(ActivatedRoute);
    this.cdr = injector.get(ChangeDetectorRef);
    this.staticContentService = injector.get(StaticContentService);
    this.elementRef = injector.get(ElementRef);
    this.vps = injector.get(ViewportScroller);
  }

  getLegalGuideData() {
    this.showSpinner = true;
    this.staticContentService.getLegalGuidelinesData().subscribe(res => {
      if (res.body.statusCode === 200) {
        this.legalGuideContent = get(res, 'body.data', '');
        this.legalGuideContent = this.legalGuideContent.replace(/""/g, '"').replace(/href/g, 'data-tagName');
        this.legalData = this.sanitizer.bypassSecurityTrustHtml(this.legalGuideContent);
        this.addClickListener();
        this.showSpinner = false;
      } else {
        this.showSpinner = false;
        this.legalDataWarning = res.body.message;
      }
    }, e => {
      this.showSpinner = false;
      this.legalDataWarning = WARNINGS.UnknownError;
      console.error(e);
    });
  }

  /**
   * On click listener for Anchor tag click
   */
  addClickListener() {
    this.elementRef.nativeElement.addEventListener('click', (event: any) => {
      let index;
      const eventPath = event?.path || event?.composedPath();
      if (eventPath?.length > 0) {
        // get the index for the anchor tag element for which nodename is 'A'
        index = eventPath.findIndex((element) => element.nodeName === 'A');
        let dataTagName;
        let targetElement;
        // Get the target element tag name to which we need scroll
        if (index === 1 && eventPath[index]?.dataset?.tagname) {
          dataTagName = eventPath[index].dataset.tagname.slice(1);
        }
        // with the tag name select the target element
        if (dataTagName) {
          targetElement = this.elementRef.nativeElement.querySelector(`[name=${dataTagName}]`);
        }
        // scroll to the corresponding target element
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
      false
    );
  }

  getDisclaimerData() {
    this.isSuperAdmin = this.userDetailService?.isSuperAdmin();
    this.staticContentService.getDisclaimerData().subscribe(res => {
      this.showSpinner = true;
      if (res.body.statusCode === 200) {
        this.disclaimerContent = get(res, 'body.data', '');
        this.disclaimerContentOperations();
        this.elementRef.nativeElement.addEventListener('click', (event: any) => {
          let index;
          const eventPath = event?.path || event?.composedPath();
          if (event && eventPath && eventPath.length > 0) {
            index = eventPath.findIndex((element) => element.nodeName === 'A');
            let dataTagName;
            if (index > -1 && eventPath[index].dataset && eventPath[index].dataset.apiurl) {
              dataTagName = eventPath[index].dataset.apiurl;
            }
            this.downloadFromAPI(dataTagName);
          }
        }, false);
      } else {
        this.disclaimerDataWarning = res.body.message;
      }
      this.showSpinner = false;
    }, e => {
      this.disclaimerDataWarning = WARNINGS.UnknownError;
      this.showSpinner = false;
      console.error(e);
    });
  }

  disclaimerContentOperations() {    
    this.disclaimerContent = this.disclaimerContent.replace(/img src=\"/g, 'img src="assets/images/static-content');
    this.disclaimerContent = this.disclaimerContent
      .replace(/tgpp-dev-use2-user-helpguides.s3.us-east-2.amazonaws.com+[/]+CurrentDisclaimer+[/]+MASTER_DISCLAIMERS_LIST-SEPT_18_2020.pdf/g,
        this.tdrsHttpEnvironmentService.api.url + '/api/v1/review/guidelines/CurrentDisclaimersList');
    this.disclaimerContent = this.disclaimerContent.replace(/width="64"/, 'width=64 hspace=10');
    this.disclaimerContent = this.disclaimerContent.replace(/href/g, 'data-apiurl');
    this.disclaimerData = this.sanitizer.bypassSecurityTrustHtml(this.disclaimerContent);
  }

  getPhotoVideoGuideData() {
    this.showSpinner = true;
    this.staticContentService.getPhotoVideoGuidelinesData().subscribe(res => {
      if (res.body.statusCode === 200) {
        this.photovideoguideContent = get(res, 'body.data', '');
        this.photovideoguideContent = this.photovideoguideContent.replace(/img src=\"/g, 'img src="assets/images/static-content');
        this.photovideoguideData = this.sanitizer.bypassSecurityTrustHtml(this.photovideoguideContent);
      } else {
        this.photovideoguideDataWarning = res.body.message;
      }
      this.showSpinner = false;
    }, e => {
      this.photovideoguideDataWarning = WARNINGS.UnknownError;
      this.showSpinner = false;
      console.error(e);
    });
  }

  tabClick(event) {
    if (event && event.index === 0) {
      this.router.navigate([ROUTES.REVIEW_GUIDELINES], { queryParams: { tab: 'legalGuidelines' } });
    }
    if (event && event.index === 1) {
      this.router.navigate([ROUTES.REVIEW_GUIDELINES], { queryParams: { tab: 'currentDisclaimers' } });
    }
    if (event && event.index === 2) {
      this.router.navigate([ROUTES.REVIEW_GUIDELINES], { queryParams: { tab: 'photovideoGuidelines' } });
    }
  }

  ngOnInit() {
    this.getLegalGuideData();
    this.getDisclaimerData();
    this.getPhotoVideoGuideData();
  }

  ngAfterViewInit() {
    this.Activatedroute.queryParams.subscribe(params => {
      if (params?.tagname === 'B') {
        setTimeout(() => {
          const dataTagName = params?.tagname;
          const targetElement = this.elementRef.nativeElement.querySelector(`[name=${dataTagName}]`);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000);
      }
      if (params.tab === 'legalGuidelines') {
        this.selectedTab = 0;
      }
      if (params.tab === 'currentDisclaimers') {
        this.selectedTab = 1;
      }
      if (params.tab === 'photovideoGuidelines') {
        this.selectedTab = 2;
      }
    });
    this.cdr.detectChanges();
  }

  downloadFromAPI(url) {
    if (isValidURL(url)) {
      this.showSpinner = true;
      let fileName = 'DISCLAIMERS LIST CLEAN & COMPARE 1.24.23.pdf';
      let myEle : any = document.getElementById("fileNameValue");
      if(myEle) { 
        fileName = myEle.value;
      }
      const updatedUrl = "CurrentDisclaimer/"+fileName;
      this.staticContentService.downLoadFiles(updatedUrl).subscribe((res) => {
        downloadFile(res.body, fileName, FILE_TYPES.PDF);
        this.showSpinner = false;
      }, () => {
        this.showSpinner = false;
      });
    }
  }

  onFileAdding(event) {
    this.disclaimerFile = event.target.files[0];
  }

  disclaimerFormSubmit(disclaimerDate: Date) {    
    this.disclaimerDate = disclaimerDate;    
    let isValid: boolean = true;
    this.isValidDisclaimerDate = true;
    this.isValidDisclaimerFile = true;    
    if (!this.disclaimerDate) {
      isValid = false;
      this.isValidDisclaimerDate = false;
    }
    if (this.disclaimerFile == null) {
      isValid = false;
      this.isValidDisclaimerFile = false;
    }
    if (isValid) {
    this.showSpinner = true;    
      this.staticContentService.getS3SignedUrlForFile(this.disclaimerFile.name,UploadStatus.NotStarted).subscribe(fileRes => {     
        if(get(fileRes, 'statusCode', null) === 200 && get(fileRes, 'data', null) != null) {        
          const signedUrl = get(fileRes, 'data', null).data.signedURL;
          this.staticContentService.uploadDisclaimerFileWithSignedUrl(this.disclaimerFile,signedUrl).subscribe((response) => {
            this.staticContentService.updateDisclaimerContent(this.disclaimerFile.name, UploadStatus.Success,this.disclaimerDate).subscribe((res1) => {
              this.disclaimerContent = get(res1, 'data', null).data.brandstandards;              
              this.disclaimerContentOperations();
              this.showSpinner = false;
              this.showDisclaimer = false;
              this.disclaimerDate = null;
              this.disclaimerFile = null;
            });
          });
        }     
      }, e => {     
        this.showSpinner = false;
        this.showDisclaimer = false;
        this.disclaimerDate = null;
        this.disclaimerFile = null;
        console.error(e);
      });
    }
  }

  openDialog(modalValue) {    
    this.showDisclaimer = modalValue;
    if (!modalValue) {
      this.disclaimerDate = null;
      this.disclaimerFile = null;
      this.isValidDisclaimerDate = true;
      this.isValidDisclaimerFile = true;
    }
  }
}