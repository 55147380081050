import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { isNil } from 'lodash';

@Component({
    selector: 'preview-popup',
    templateUrl: 'preview-popup.component.html',
    styleUrls: ['./preview-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PreviewPopupComponent implements OnInit {
    announcement: string = '';
    parent: any;
    saveSiteNotificationParent: any;
    imageDataUrl: any;
    assetInfo: string = '';
    mediaType: string;
    previewUrl: string;
    pdfStoryBoardUrls: string[] = [];
    cuurentStoryBoardUrl: string;
    cuurentStoryBoardUrlIndex: number = 0;
    constructor() { }

    ngOnInit() {
       
    }

}
