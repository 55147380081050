import { FILE_TYPES } from '../../app.constant';

export function isChromeBrowser(): boolean {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  }

  /*
 * Pull a value or use a default value for an object
 *
 * @deprecated Use initialize instead.
 *
 * @param obj The object to search
 * @param prop The property to pull off the object, if it is present
 * @param value The value to use if object.property is undefined
 */
export function init(obj: any, prop: string, value?: any): any {
  return hasOwnProperty(obj, prop) ? obj[prop] : value;
}

export function hasOwnProperty(o: any, prop: string): boolean {
  return isDefined(o) && o.hasOwnProperty(prop);
}

export function isNull(obj: any): boolean {
  return obj === null;
}

export function isUndefined(obj: any): boolean {
  return obj === undefined;
}

export function isNullOrUndefined(obj: any): boolean {
  return isNull(obj) || isUndefined(obj);
}

export function isDefined(obj: any): boolean {
  return !isNullOrUndefined(obj);
}


export function isStringWithLength(obj: any): obj is string {
  return isString(obj) && obj.length > 0;
}

export function isString(obj: any): obj is string {
  return isDefined(obj) && typeof obj === 'string';
}

export function replaceKeyValueWithString(
  keyValueString: string,
  replacementString: string | { [keyToReplace: string]: string }
): string {
  let val = '';

  if (isStringWithLength(keyValueString)) {
    if (isObject(replacementString)) {
      if (!Object.entries) {
        Object.entries = (entObj: any): any[] => {
          const ownProps = Object.keys(entObj);
          const i = ownProps.length;
          const resArray = new Array(i); // preallocate the Array
          while (i>=0) {
            resArray[i] = [ownProps[i], entObj[ownProps[i]]];
          }
          return resArray;
        };
      }
      val = keyValueString;
      Object.entries(replacementString).forEach(([key, value]) => {
        const regex = new RegExp(`${key}`, 'g');
        if (regex.test(val)) {
          val = val.replace(regex, value);
        }
      });
    }
  }

  return val;
}

export function isObject(obj: any): obj is object {
  if (isDefined(obj)) {
    return !isArray(obj) && typeof obj === 'object';
  }
  return false;
}

export function isArray(obj: any): obj is any[] {
  return isDefined(obj) && Array.isArray(obj);
}

export function isArrayWithLength(obj: any): obj is any[] {
  return isArray(obj) && obj.length > 0;
}

export function isValidURL(urlStr) {
  const reg = new RegExp('^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!reg.test(urlStr);
}

export function getFileIcon(fileName) {
  const newFileName=fileName?.toLowerCase() || '';
  if (newFileName.match('.xls') || newFileName.match('.xlsx') || newFileName.match('.csv')) {
    return 'assets/images/new-excel-icon.png';
  } else if (newFileName.match('.pdf')) {
    return 'assets/images/new-pdf-icon.png';
  } else if (newFileName.match('.doc') || newFileName.match('.docx')) {
    return 'assets/images/new-word-icon.png';
  } else if (newFileName.match('.jpeg') || newFileName.match('.jpg')) {
    return 'assets/images/new-jpeg-icon.png';
  } else if (newFileName.match('.zip')) {
    return 'assets/images/new-zip-icon.png';
  }else if (newFileName.match('.ppt') || newFileName.match('.pptx')) {
    return 'assets/images/new-ppt-icon.png';
  }else if (newFileName.match('.png')) {
    return 'assets/images/new-png-icon.png';
  } else if (newFileName.match('.mp3') || newFileName.match('.wav') || newFileName.match('.aiff') || newFileName.match('.aac') || newFileName.match('.wma')) {
    return 'assets/images/new-audio-icon.png';
  } else {
    return 'assets/images/no-doc-icon.png';
  }  
}

export function differentiateFile(fileName) {
  const newFileName=fileName?.toLowerCase() || '';
  if (newFileName.match('.xls') || newFileName.match('.xlsx') || newFileName.match('.csv') || newFileName.match('.pdf') || newFileName.match('.doc') || newFileName.match('.docx') || newFileName.match('.jpeg') || newFileName.match('.jpg') || newFileName.match('.zip') || newFileName.match('.ppt') || newFileName.match('.pptx') || newFileName.match('.png')) {
    return 'document';
  } else if (newFileName.match('.mp3') || newFileName.match('.wav') || newFileName.match('.aiff') || newFileName.match('.aac') || newFileName.match('.wma')) {
    return 'audio';
  } else {
    return '';
  }
}

const getMimeType = new Map();
getMimeType.set('doc', FILE_TYPES.MSWORD);
getMimeType.set('docx', FILE_TYPES.DOCX);
getMimeType.set('xls', FILE_TYPES.XLS);
getMimeType.set('xlsx', FILE_TYPES.XLSX);
getMimeType.set('pdf', FILE_TYPES.PDF);
getMimeType.set('zip', FILE_TYPES.ZIP);
getMimeType.set('ppt', FILE_TYPES.PPT);
getMimeType.set('pptx', FILE_TYPES.PPTX);
getMimeType.set('jpeg', FILE_TYPES.IMG);
getMimeType.set('jpg', FILE_TYPES.IMG);
getMimeType.set('mp3', FILE_TYPES.MP3);
getMimeType.set('wav', FILE_TYPES.WAV);
getMimeType.set('aiff', FILE_TYPES.AIFF);
getMimeType.set('aac', FILE_TYPES.AAC);
getMimeType.set('wma', FILE_TYPES.WMA);

export const getFileMimeType = (filePath: string) => {
  const filePathArr = filePath.split('.');
  if (filePathArr?.length > 0) {
    return getMimeType.get(filePathArr[filePathArr.length - 1]);
  } else {
    return 'application/pdf | application/msword | application/vnd.ms-excel';
  }
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
