import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '../../../../src/app/app-routes.constant';

@Component({
  selector: 'dd-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  goHome() {
    this.router.navigateByUrl(ROUTES.HOME);
  }

}
