import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'dd-tgpp-file-upload',
  templateUrl: './tgpp-file-upload.component.html',
  styleUrls: ['./tgpp-file-upload.component.scss']
})
export class TgppFileUploadComponent implements OnInit {
  @Input() filTypes = '.pdf,.doc, .xlsx';
  @Input() buttonType = '';
  @Output() onInputChange:EventEmitter<any> =new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  onFileChange(event) {
    this.onInputChange.emit(event);
  }

}
