import { PreviewPopupComponent } from './preview-popup.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { isNil, isEmpty } from 'lodash';

/**
 * This class is used to show the Dialog component in the screen
 */
@Injectable()
export class PreviewPopupService {
    dialogRef: MatDialogRef<PreviewPopupComponent>;
    /**
     * constructor method to create new instance of Alert Dialog
     */
    constructor(public dialog: MatDialog) { }

    /**
     * Method to open the alert dialog in the page
     */
    public open(imageDataUrl: string,mediaType: string, previewUrl: string): Observable<boolean> {
        let config = new MatDialogConfig();
        config.disableClose = false;
        this.dialogRef = this.dialog.open(PreviewPopupComponent, config);
        this.dialogRef.componentInstance.imageDataUrl = imageDataUrl;
        this.dialogRef.componentInstance.mediaType = mediaType;
        this.dialogRef.componentInstance.previewUrl = previewUrl;

        return this.dialogRef.afterClosed();
    }
}