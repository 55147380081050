import { Component, OnInit, Injector } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { ROUTES } from '../../app-routes.constant';
import {Router} from '@angular/router';


@Component({
  selector: 'dd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public pathSelected :string = 'login';
  public errorMsg;
  public signUp: any = {
    reviewer: {
      title: 'Reviewer or Review Leader',
      link: ROUTES.REVIEWER_REGISTRATION
    },
    agencyUser: {
      title: 'External Agency or Graphics Partner',
      link: ROUTES.USER_REGISTRATION
    }
  }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  constructor(public router: Router, injector: Injector) {
  }
  
  navigateMenu(tag: string) {
    if (tag === 'reviewer' || tag === 'agencyUser') {
      this.router.navigate([this.signUp.reviewer.link]);
    }
    if (tag === 'login') {
      if (this.emailFormControl.valid &&
        this.passwordFormControl.valid &&
        this.emailFormControl.value === atob('YWRtaW5AdGVzdC5jb20=') &&
        this.passwordFormControl.value=== atob('VGVzdEAxMjM=').toString()) {
        localStorage.setItem('token', new Date().toString());
        this.router.navigate(['/home']);
      }else{
        this.errorMsg = 'Invalid Login';
      }
    }
  }
  ngOnInit() {
  }

}
